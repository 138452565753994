import { Box } from '@mui/material'
import { Text } from 'lune-ui-lib'
import React, { FC } from 'react'

const PrivacyAndTerms: FC = () => {
    return (
        <Box
            sx={{
                a: {
                    textDecoration: 'underline !important',
                    fontStyle: 'normal',
                    color: 'inherit',
                },
            }}
        >
            <Text variant={'body3'} sx={{ mt: 2, display: 'block' }}>
                <a target="_blank" href="https://lune.co/privacy">
                    Privacy
                </a>{' '}
                •{' '}
                <a target="_blank" href="https://lune.co/terms">
                    Terms of Service
                </a>
            </Text>
        </Box>
    )
}

export default PrivacyAndTerms
