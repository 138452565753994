import Box from '@mui/material/Box'
import { Text } from 'lune-ui-lib'
import React from 'react'

import FrameLayout from 'components/FrameLayout'
const gradientBg = require('images/gradientBg.png')

const PageNotFound = () => {
    return (
        <FrameLayout>
            <Box
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'stretch',
                    p: 2,
                    pt: 0,
                    a: {
                        textDecoration: 'none !important',
                    },
                }}
            >
                <Box
                    sx={{
                        p: 6,
                        width: '100%',
                        flex: '1 1 auto',
                        borderRadius: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundImage: `url(${gradientBg})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Box>
                        <Text variant={'h4'}>404</Text>
                        <Text variant={'h4'} sx={{ mb: 4 }}>
                            Page not found
                        </Text>
                    </Box>
                </Box>
            </Box>
        </FrameLayout>
    )
}

export default PageNotFound
