import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Big from 'big.js'
import { Dispatch, SetStateAction } from 'react'

import PaymentForm from 'pages/PaymentPage/PaymentForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!)

const PaymentFormWrapper = ({
    accountId,
    quantity,
    idempotencyKey,
    setCountry,
}: {
    accountId: string
    quantity: Big
    idempotencyKey: string
    setCountry: Dispatch<SetStateAction<string | undefined>>
}) => (
    <Elements stripe={stripePromise}>
        <PaymentForm
            accountId={accountId}
            quantity={quantity}
            idempotencyKey={idempotencyKey}
            setCountry={setCountry}
        />
    </Elements>
)

export default PaymentFormWrapper
