import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import { AccordionTile, LuneTheme, Text } from 'lune-ui-lib'

const FAQs = () => {
    const { palette } = LuneTheme

    return (
        <>
            <Text variant={'h6'} sx={{ mb: 4 }}>
                FAQs
            </Text>
            <AccordionTile
                title={
                    <Text variant={'body2'}>What is carbon offsetting and why do we need it?</Text>
                }
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Contributing to climate projects that either reduce emissions or remove CO₂ from
                    the atmosphere. Often done to compensate for carbon emissions.
                    <br />
                    <br />
                    We have emitted 100s of billions of tCO₂ into the atmosphere — we have to scale
                    carbon removal. We are deforesting at an unprecedented pace — we have to protect
                    and conserve nature.
                </Text>
            </AccordionTile>
            <AccordionTile
                title={
                    <Text variant={'body2'}>
                        Why is there such a big price difference between projects?
                    </Text>
                }
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    The most innovative carbon removal methodologies are often still up and coming
                    and lack the scale benefits of more established methodologies. At the same time,
                    the innovative projects tend to be higher quality than the traditional carbon
                    offset projects. This comes at a price.
                    <br />
                    <br />
                    To support the most innovative projects, you can reduce the amount of tCO₂ you
                    buy.
                </Text>
            </AccordionTile>
            <AccordionTile
                title={
                    <Text variant={'body2'}>What happens after I’ve completed the purchase?</Text>
                }
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    You will receive an email order confirmation. Lune will then allocate and retire
                    the carbon offset credits on your behalf. You will receive a carbon offset
                    certificate to prove that you have the claim to the climate benefits generated.
                    Note that some carbon credits will retired immediately while some can be retired
                    in the future.
                </Text>
            </AccordionTile>
            <AccordionTile
                title={
                    <Text variant={'body2'}>
                        How do I know these projects are having a real impact?
                    </Text>
                }
                icon={<HelpOutlineIcon />}
            >
                <Text variant={'body3'} sx={{ color: palette.Grey600 }}>
                    Lune specialises in high-quality carbon offsetting. Lune combines in-house
                    analysis and leading external offset evaluations to ensure trust in the impact
                    generated.
                </Text>
            </AccordionTile>
        </>
    )
}

export default FAQs
