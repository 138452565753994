import Box from '@mui/material/Box'
import { useIsMobile } from 'lune-ui-lib'
import React from 'react'

import PrivacyAndTerms from 'components/PrivacyAndTerms'

const PaymentLayout = ({
    backgroundImageUrl,
    gradientColor,
    columnOne,
    columnTwo,
}: {
    backgroundImageUrl: string
    gradientColor: string
    columnOne: React.ReactNode
    columnTwo: React.ReactNode
}) => {
    const { isMobile } = useIsMobile()
    return (
        <Box
            sx={{
                height: '100%',
                pb: {
                    xs: 0,
                    sm: 2,
                },
                pl: {
                    xs: 0,
                    sm: 2,
                },
                pr: {
                    xs: 0,
                    sm: 2,
                },
            }}
        >
            <Box
                sx={{
                    ...(!isMobile &&
                        backgroundImageUrl && {
                            backgroundImage: `url("${backgroundImageUrl}")`,
                            backgroundSize: 'cover',
                            borderRadius: {
                                xs: `0px`,
                                sm: `32px`,
                                md: `32px`,
                                lg: `32px`,
                                xl: `32px`,
                            },
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                        }),
                    height: '100%',
                }}
            >
                <Box
                    sx={{
                        borderRadius: `32px`,
                        padding: isMobile ? '0 16px' : '72px 32px',
                        pb: isMobile ? '16px' : '72px',
                        width: '100%',
                        height: '100%',
                        ...(!isMobile && {
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex',
                            ...(gradientColor && {
                                backgroundImage: `linear-gradient(to right, ${
                                    gradientColor + 'FF'
                                }, ${gradientColor + '00'})`,
                            }),
                        }),
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '1120px',
                            margin: 'auto',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'stretch',
                            justifyContent: 'space-between',
                            gap: {
                                xs: 6,
                                sm: 10,
                                md: 10,
                                lg: 18,
                            },
                            flexDirection: isMobile ? 'column' : 'row',
                        }}
                    >
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '50%',
                                ...(isMobile && {
                                    backgroundImage: `url("${backgroundImageUrl}")`,
                                    backgroundSize: 'cover',
                                    borderRadius: '32px',
                                }),
                            }}
                        >
                            <Box
                                sx={{
                                    height: '100%',
                                    ...(isMobile && {
                                        backgroundImage: `linear-gradient(0deg, ${
                                            gradientColor + 'FF 38.62%'
                                        }, ${gradientColor + '00'} 100%)`,
                                        p: 4,
                                        borderRadius: '32px',
                                    }),
                                }}
                            >
                                {columnOne}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                pb: 13,
                                width: '100%',
                                ...(!isMobile && {
                                    pb: 0,
                                    height: `100%`,
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    pt: `0px !important`,
                                    width: '50%',
                                }),
                            }}
                        >
                            {columnTwo}
                            {isMobile && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        mt: 4,
                                    }}
                                >
                                    <PrivacyAndTerms />
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default PaymentLayout
