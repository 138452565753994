import React from 'react'

const useScrollRestoration = () => {
    const restoreScrollPosition = (
        containerRef: React.RefObject<HTMLDivElement>,
        sessionStorageName: string,
    ) => {
        const scrollPositionLeft = sessionStorage.getItem(sessionStorageName)
        if (scrollPositionLeft) {
            containerRef.current!.scrollTo({ left: parseInt(scrollPositionLeft) })
        }
    }

    const saveScrollPosition = (scrollPosition: number, sessionStorageName: string) => {
        if (scrollPosition) {
            sessionStorage.setItem(sessionStorageName, scrollPosition.toString())
        }
    }

    return {
        restoreScrollPosition,
        saveScrollPosition,
    }
}

export default useScrollRestoration
