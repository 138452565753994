import { Big } from 'big.js'

import { Order } from 'pages/Success/SuccessPage'

import { bootstrapAxiosClient } from './util'

const dapi = bootstrapAxiosClient(`${process.env.REACT_APP_DAPI_URL}/v1`)
const api = bootstrapAxiosClient(`${process.env.REACT_APP_API_URL}/v1`)

export const loadBundles = (data: {
    accountId: string
    bundleIds?: string[]
    externalId?: string
}): Promise<any> => {
    const bundles = data.bundleIds?.length
        ? data.bundleIds.map((b) => `bundle_id=${b}`).join('&')
        : ''
    const externalId = data.externalId ? `&external_id=${data.externalId}` : ''

    const url = `/pay/${data.accountId}?${bundles}${externalId}`
    return dapi.get(url)
}

export type initiatePaymentRequest = {
    quantity: Big
    email: string
    cardHolderName: string
    idempotencyKey: string
    countryCode: string
    vatNumber?: string
    bundleId: string
    totalCost: string
    testOutcome?: 'success' | 'card_declined'
    externalId?: string
}

export const initiatePayment = (
    accountId: string,
    data: initiatePaymentRequest,
): Promise<{
    temporaryId: string
    secret: string
}> => {
    return dapi.post(`/pay/${accountId}`, {
        ...data,
        quantity: data.quantity.toString(),
    })
}

export const initiateTestPayment = (
    accountId: string,
    data: initiatePaymentRequest,
    testOutcome: 'success' | 'card_declined',
): Promise<{
    temporaryId: string
    secret: string
}> => {
    return dapi.post(`/pay/test/${accountId}`, {
        ...data,
        quantity: data.quantity.toString(),
        testOutcome,
    })
}

export const loadPaymentByTemporaryId = (data: {
    temporaryId: string
}): Promise<{
    type: string
    order: Order
}> => api.get(`/payments/by-temporary-id/${data.temporaryId}`)
