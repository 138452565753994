import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'

export const bootstrapAxiosClient = (baseURL: string) => {
    const client = axios.create({ baseURL })

    // Transform request payload keys camelCase --> snake_case
    client.interceptors.request.use((req) => {
        const requestCopy = { ...req }

        if (requestCopy.params) {
            requestCopy.params = snakeCaseKeys(req.params, { deep: true })
        }

        if (requestCopy.data) {
            requestCopy.data = snakeCaseKeys(req.data, { deep: true })
        }

        return requestCopy
    })

    // Transform response data keys snake_case --> camelCase
    client.interceptors.response.use(
        (response) => camelCaseKeys(response.data, { deep: true }),
        (error) => Promise.reject(error.response),
    )

    return client
}
