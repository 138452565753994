import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { LuneTheme, LuneThemeProvider } from 'lune-ui-lib'
import React from 'react'
import ReactDOM from 'react-dom/client'

import ErrorPage from 'pages/ErrorPage'

import App from './App'

import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const app = (
    <LuneThemeProvider theme={LuneTheme}>
        <App />
    </LuneThemeProvider>
)

if (process.env.REACT_APP_PAYMENTS_BUGSNAG_KEY) {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_PAYMENTS_BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact()],
    })
    const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React)
    root.render(
        // @ts-ignore
        <ErrorBoundary FallbackComponent={ErrorPage}>{app}</ErrorBoundary>,
    )
} else {
    root.render(app)
}
