import { Big } from 'big.js'
import getUserLocale from 'get-user-locale'

export const formatToCurrency = (number: number | string | Big, currency: string) => {
    if (!currency) {
        return number.toString()
    }

    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    try {
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
            currencyDisplay: 'narrowSymbol',
        })
    } catch (e) {
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale, {
            style: 'currency',
            currency,
        })
    }

    return numberFormat.format(Big(number).toNumber())
}

export const formatNumbers = (number: number | string | Big): string => {
    const userLocale = getUserLocale() || 'en-GB'
    let numberFormat

    try {
        numberFormat = new Intl.NumberFormat(userLocale)
    } catch (e) {
        // Fallback config omitting currencyDisplay: 'narrowSymbol',
        // Mainly needed for Safari 10-14
        // Details https://caniuse.com/mdn-javascript_builtins_intl_numberformat_numberformat_currencydisplay
        numberFormat = new Intl.NumberFormat(userLocale)
    }

    try {
        return numberFormat.format(Big(number).toNumber())
    } catch (e) {}

    return number.toString()
}

export const formatAmount = (amount: string) => {
    const formattedAmount = formatNumbers(amount)
    return `${formattedAmount} tCO2`
}
