import { Box } from '@mui/material'
import React, { FC } from 'react'

const TornPage: FC<{ children: React.ReactNode }> = ({ children }) => {
    const sharedSx = (color: string) => {
        return {
            '  --mask': 'radial-gradient(6px at 48% 12px,#0000 91%,#000) 6px -10px/16.5px 99%',
            '-webkit-mask': 'var(--mask)',
            mask: `var(--mask)`,
            clipPath: 'none',
            background: color,
        }
    }

    return (
        <Box
            sx={{
                maxWidth: '448px',
                position: 'relative',
                pl: '12px',
                pb: '12px',
                ml: '-12px',
                width: 'calc(100% + 12px)',
            }}
        >
            <Box
                sx={{
                    zIndex: '0',
                    opacity: '0.15',
                    left: '0px',
                    top: '12px',
                    right: '12px',
                    bottom: '0',
                    position: 'absolute',
                    ...sharedSx('black'),
                }}
            />
            <Box
                sx={{
                    zIndex: '1',
                    top: 0,
                    left: '10px',
                    bottom: '14px',
                    ...sharedSx('white'),
                }}
            >
                {children}
            </Box>
        </Box>
    )
}

export default TornPage
