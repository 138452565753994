import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { Button, ListItemLayout, Modal, Text, useIsMobile } from 'lune-ui-lib'
import React, { FC, useRef, useState } from 'react'

import { Bundle } from 'components/Bundles'

const CollapsableDescription = ({ description }: { description: string }) => {
    const [descriptionExpanded, setDescriptionExpanded] = useState<boolean>(false)
    const { isMobile } = useIsMobile()
    const descriptionRef = useRef<HTMLFormElement>(null)

    return (
        <Box
            sx={{
                width: '100%',
                ...(!isMobile
                    ? {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: 4,
                      }
                    : ''),
            }}
        >
            <Box
                sx={{
                    ...(!isMobile && {
                        height: descriptionExpanded
                            ? `${descriptionRef.current?.clientHeight}px`
                            : '85px',
                        transition: 'height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        display: 'flex',
                        alignItems: 'flex-start',
                        overflow: 'hidden',
                    }),
                }}
            >
                <Text ref={descriptionRef} variant={'body3'}>
                    {description}
                </Text>
            </Box>
            {!isMobile && (
                <Button
                    sx={{
                        flex: '1 0 auto',
                        transform: descriptionExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                        transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        transformOrigin: 'center',
                    }}
                    onClick={() => setDescriptionExpanded(!descriptionExpanded)}
                    iconButton
                    variant={'text'}
                    leftIcon={<KeyboardArrowDownIcon />}
                />
            )}
        </Box>
    )
}

const LearnMoreBundleModal: FC<{
    openModal: boolean
    setOpenModal: (open: boolean) => void
    bundle: Bundle
}> = ({ openModal, setOpenModal, bundle }) => {
    const { isMobile } = useIsMobile()

    const mobileSx = {
        borderRadius: '0',
        height: '100%',
        margin: 0,
        maxWidth: '100%',
        maxHeight: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        p: '0 24px',
        pb: 5,
    }

    return (
        <Modal
            maxWidth={'sm'}
            fullWidth
            open={openModal}
            onClose={() => {
                setOpenModal(false)
            }}
            sx={{
                '.MuiPaper-root': {
                    ...(!isMobile
                        ? {
                              maxWidth: '800px',
                              borderRadius: '24px',
                              p: 6,
                          }
                        : ''),
                    ...(isMobile ? mobileSx : ''),
                },
            }}
            header={
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    {isMobile && (
                        <Button
                            iconButton
                            leftIcon={<CloseIcon sx={{ width: '24px' }} />}
                            variant={'text'}
                            onClick={() => setOpenModal(false)}
                            sx={{
                                mb: 5,
                                mt: 1,
                                ml: -2,
                            }}
                        />
                    )}
                    <Text
                        variant={'h5'}
                        sx={{
                            mb: -1,
                            textAlign: 'center',
                        }}
                    >
                        {bundle.name}
                    </Text>
                </Box>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Button variant={'outlined'} wide onClick={() => setOpenModal(false)}>
                        Close
                    </Button>
                </Box>
            }
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <Stack spacing={{ xs: 5 }}>
                    {bundle.projects.map((project) => {
                        return (
                            <Box
                                key={`bundle-project-${project.id}`}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: isMobile ? 2 : 4,
                                    ...(isMobile && {
                                        flexDirection: 'column',
                                    }),
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '200px',
                                        height: '200px',
                                        overflow: 'hidden',
                                        backgroundImage: `url('${project.thumbnailImage}')`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        borderRadius: '32px',
                                        flex: '1 0 auto',
                                        ...(isMobile && {
                                            width: '100%',
                                        }),
                                    }}
                                />
                                <Box sx={{ mb: 2 }}>
                                    <ListItemLayout
                                        image={
                                            project.logo ? (
                                                <img
                                                    width={48}
                                                    height={48}
                                                    src={project.logo}
                                                    alt={project.name}
                                                />
                                            ) : (
                                                <PinDropOutlinedIcon
                                                    sx={{ width: '20px', height: '20px' }}
                                                />
                                            )
                                        }
                                        subTitle={project.countryName}
                                        title={project.name}
                                    />
                                    <CollapsableDescription description={project.description} />
                                </Box>
                            </Box>
                        )
                    })}
                </Stack>
            </Box>
        </Modal>
    )
}

export default LearnMoreBundleModal
