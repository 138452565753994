import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { Big } from 'big.js'
import { BackButton, LuneTheme, Text, useIsMobile } from 'lune-ui-lib'

import { Account } from 'pages/ChooseBundlePage/ChooseBundlePage'
import { formatNumbers, formatToCurrency } from 'utils/Utils'

import { Bundle } from './Bundles'
import PrivacyAndTerms from './PrivacyAndTerms'

const BundlePaymentBreakdown = ({
    bundle,
    account,
    quantity,
    selectedCountry,
    onBackButtonClick,
}: {
    bundle: Bundle
    account: Account
    quantity: Big
    selectedCountry: string | undefined
    onBackButtonClick: () => void
}) => {
    const { palette } = LuneTheme
    const { isMobile } = useIsMobile()

    const sx = {
        color: palette.White,
    }
    const addVat = selectedCountry === 'GBR'
    const vatPrice = quantity.mul(Big(0.2)).mul(Big(bundle.grossUnitPrice).sub(bundle.unitPrice))
    const bundlePrice = quantity.mul(bundle.grossUnitPrice)

    return (
        <Box
            sx={{
                pt: isMobile ? 9 : 0,
                ...(!isMobile && {
                    height: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }),
            }}
        >
            <Box>
                {!isMobile && (
                    <BackButton
                        iconColor={palette.White}
                        sx={{ ml: -2, mb: 4 }}
                        onClick={onBackButtonClick}
                    />
                )}

                <Text variant="h6" sx={sx}>
                    {formatNumbers(quantity)} tCO<sub>2</sub>
                </Text>
                <Text variant="h4" sx={{ ...sx, mb: { xs: 4, s: 5 } }}>
                    {bundle.name}
                </Text>
                <Text variant="body3" sx={sx}>
                    You will receive a Carbon Credit Certificate for this purchase, as soon as these
                    credits are retired.
                </Text>

                <Stack
                    direction="column"
                    spacing={4}
                    sx={{
                        borderTop: '1px solid rgba(255,255,255,0.2)',
                        borderBottom: '1px solid rgba(255,255,255,0.2)',
                        my: 4,
                        py: 6,
                        width: '100%',
                    }}
                >
                    <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                        <Stack direction="column">
                            <Text variant="body2" sx={sx}>
                                {bundle.name}
                            </Text>
                            <Text variant="body3" sx={{ ...sx, opacity: 0.6 }}>
                                {formatNumbers(quantity)} tCO<sub>2</sub>
                            </Text>
                        </Stack>
                        <Text variant="body2" sx={sx}>
                            {formatToCurrency(bundlePrice, account.currency)}
                        </Text>
                    </Stack>

                    {addVat && (
                        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                            <Text variant="body2" sx={sx}>
                                VAT
                            </Text>
                            <Text variant="body2" sx={sx}>
                                {formatToCurrency(vatPrice, account.currency)}
                            </Text>
                        </Stack>
                    )}
                </Stack>

                <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
                    <Text variant="h6" sx={sx}>
                        Total
                    </Text>
                    <Text variant="h6" sx={sx}>
                        {addVat
                            ? formatToCurrency(bundlePrice.add(vatPrice), account.currency)
                            : formatToCurrency(bundlePrice, account.currency)}
                    </Text>
                </Stack>
            </Box>
            {!isMobile && (
                <Box
                    sx={{
                        '*': {
                            color: `${palette.White} !important`,
                        },
                    }}
                >
                    <PrivacyAndTerms />
                </Box>
            )}
        </Box>
    )
}

export default BundlePaymentBreakdown
