import Box from '@mui/material/Box'
import { BackButton, useIsMobile, XlContainer } from 'lune-ui-lib'
import React, { FC } from 'react'

// @ts-ignore
import { ReactComponent as SVGLogo } from 'images/lune-logo.svg'

// eslint-disable-next-line no-undef
const FrameLayout: FC<{
    children: React.ReactNode
    onBackButtonClick?: () => void
}> = ({ children, onBackButtonClick }) => {
    const { isMobile } = useIsMobile()
    return (
        <XlContainer
            sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '0 !important',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    height: '72px',
                    padding: '0 24px',
                    width: '100%',
                }}
            >
                {isMobile && onBackButtonClick && (
                    <BackButton
                        sx={{ ml: -2 }}
                        onClick={() => {
                            onBackButtonClick()
                        }}
                    />
                )}
                <Box
                    sx={{
                        flex: '1 1 auto',
                        mr: isMobile && !!onBackButtonClick ? 8 : 0,
                        textAlign: 'center',
                    }}
                >
                    <SVGLogo width={96} />
                </Box>
            </Box>
            <Box
                sx={{
                    minHeight: 'calc(100vh - 72px)',
                    width: '100%',
                }}
            >
                {children}
            </Box>
        </XlContainer>
    )
}

export default FrameLayout
