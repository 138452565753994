import Box from '@mui/material/Box'
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Big } from 'big.js'
import { LuneTheme, Text, useIsMobile } from 'lune-ui-lib'
import { nanoid } from 'nanoid'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { Bundle } from 'components/Bundles'
import { initiatePayment, initiatePaymentRequest, initiateTestPayment } from 'endpoints/endpoints'
import PaymentFormInputs from 'pages/PaymentPage/PaymentFormInputs'

export enum FormKeys {
    EMAIL = 'email',
    NAME = 'card_holder_name',
    COUNTRY = 'countryCode',
    POST_CODE = 'postcode',
    VAT = 'vat_number',
    TEST_OUTCOME = 'testOutcome',
}

export type initiatePaymentRequestWithoutQuantity = Omit<
    initiatePaymentRequest,
    'quantity' | 'idempotencyKey'
>

const PaymentForm = ({
    accountId,
    quantity,
    idempotencyKey,
    setCountry,
}: {
    accountId: string
    quantity: Big
    idempotencyKey: string
    setCountry: Dispatch<SetStateAction<string | undefined>>
}) => {
    const navigate = useNavigate()
    const { spacing } = LuneTheme
    const stripe = useStripe()
    const elements = useElements()
    const { isMobile } = useIsMobile()
    const [cardErrorMessage, setCardErrorMessage] = useState<string | undefined>()
    const paymentFormRef = useRef<null | HTMLDivElement>(null)
    const location = useLocation()
    const [localIdempotencyKey, setLocalIdempotencyKey] = useState<string>(idempotencyKey)

    const [stateData, setStateData] = useState<{
        isOnTestMode: boolean
        redirectData: {
            redirectUrl: string
            redirectLabel: string
        }
        bundle: Bundle
        quantity: string
        externalId: string | undefined
    }>()

    const [submittingPayment, setSubmittingPayment] = useState<boolean>(false)

    useEffect(() => {
        if (location.state) {
            const { isOnTestMode, redirectData, bundle, quantity, externalId } = location.state
            const state = {
                isOnTestMode,
                redirectData,
                bundle,
                quantity,
                externalId,
            }
            setStateData(state)
        }
    }, [location])

    useEffect(() => {
        setLocalIdempotencyKey(idempotencyKey)
    }, [idempotencyKey])

    const navigateToSuccessPage = (
        values: initiatePaymentRequestWithoutQuantity,
        temporaryId: string,
    ) => {
        navigate('/success', {
            state: {
                email: values.email,
                countryCode: values.countryCode,
                temporaryId,
                isOnTestMode: stateData?.isOnTestMode,
                redirectData: stateData?.redirectData,
                externalId: stateData?.externalId,
            },
        })
    }

    const handleSubmit = async (values: initiatePaymentRequestWithoutQuantity) => {
        setSubmittingPayment(true)
        const q = Big(quantity)
        const bundlePrice = q.mul(stateData?.bundle.grossUnitPrice || '0')

        if (stateData?.isOnTestMode) {
            const initiatePaymentRes = await initiateTestPayment(
                accountId,
                {
                    ...values,
                    quantity,
                    idempotencyKey: localIdempotencyKey,
                    totalCost: bundlePrice.toString(),
                    bundleId: stateData.bundle.id,
                    externalId: stateData.externalId,
                },
                values.testOutcome!,
            )

            if (initiatePaymentRes.temporaryId && values.testOutcome === 'success') {
                navigateToSuccessPage(values, initiatePaymentRes.temporaryId)
            } else {
                setCardErrorMessage('Something went wrong')
                setLocalIdempotencyKey(nanoid())
                setSubmittingPayment(false)
            }
            return
        }

        const initiatePaymentRes = await initiatePayment(accountId, {
            ...values,
            quantity,
            idempotencyKey: localIdempotencyKey,
            totalCost: bundlePrice.toString(),
            bundleId: stateData!.bundle.id,
            externalId: stateData!.externalId,
        })

        if (elements == null) {
            return
        }

        const stripeRes = await stripe?.confirmCardPayment(initiatePaymentRes.secret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)!,
            },
        })

        if (stripeRes?.error) {
            setCardErrorMessage(stripeRes.error.message || 'Something went wrong')
            setLocalIdempotencyKey(nanoid())
            setSubmittingPayment(false)
            return
        }

        setSubmittingPayment(false)
        navigateToSuccessPage(values, initiatePaymentRes.temporaryId)
    }

    return (
        <Box
            ref={paymentFormRef}
            sx={{
                borderRadius: '32px',
                p: isMobile ? 0 : spacing(4),
                background: 'white',
                width: '100%',
            }}
        >
            <Text
                variant={'h4'}
                sx={{
                    mb: spacing(4),
                }}
            >
                Pay by card
            </Text>

            <Form<initiatePaymentRequestWithoutQuantity>
                initialValues={{
                    [FormKeys.EMAIL]: '',
                }}
                sx={{
                    width: '100%',
                }}
                onSubmit={handleSubmit}
                render={(formRenderProps) => {
                    return (
                        <PaymentFormInputs
                            formRenderProps={formRenderProps}
                            paymentFormRef={paymentFormRef}
                            idempotencyKey={idempotencyKey}
                            setCountry={setCountry}
                            setLocalIdempotencyKey={setLocalIdempotencyKey}
                            submittingPayment={submittingPayment}
                            cardErrorMessage={cardErrorMessage}
                            setCardErrorMessage={setCardErrorMessage}
                        />
                    )
                }}
            />
        </Box>
    )
}

export default PaymentForm
