import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { Button, CountryPicker, LuneTheme, Select, useIsMobile } from 'lune-ui-lib'
import React, { MutableRefObject, useEffect, useMemo, useState } from 'react'
import { Field, FormRenderProps } from 'react-final-form'
import { useLocation } from 'react-router-dom'

import { Bundle } from 'components/Bundles'
import FormInput from 'components/FormInput'
import TestModeBanner from 'components/TestModeBanner'
import { FormKeys, initiatePaymentRequestWithoutQuantity } from 'pages/PaymentPage/PaymentForm'

const StripeElementWrapper = ({
    children,
    cardErrorMessage,
}: {
    children: React.ReactNode
    cardErrorMessage?: string
}) => (
    <Box
        sx={{
            backgroundColor: cardErrorMessage ? LuneTheme.palette.Red50 : LuneTheme.palette.Grey100,
            px: 1.5,
            py: 2.3125,
            borderRadius: '8px',
        }}
    >
        {children}
    </Box>
)

const required = (value?: string) => (value?.trim() ? undefined : 'This is a required field')
const validateEmail = (value: string | undefined) => {
    if (!value?.trim()) {
        return 'This is a required field'
    } else if (!value.toLowerCase().match(/^[^@]+@[^@]+$/)) {
        return 'Please enter a valid email address'
    } else {
        return undefined
    }
}

interface FormInputsProps<T> {
    formRenderProps: FormRenderProps<T>
    idempotencyKey: string
    setLocalIdempotencyKey: (e: string) => void
    setCountry: (e: string | undefined) => void
    submittingPayment: boolean
    cardErrorMessage: string | undefined
    setCardErrorMessage: (e: string | undefined) => void
    paymentFormRef: MutableRefObject<null | HTMLDivElement>
}

const PaymentFormInputs = ({
    formRenderProps,
    idempotencyKey,
    setCountry,
    setLocalIdempotencyKey,
    submittingPayment,
    cardErrorMessage,
    setCardErrorMessage,
    paymentFormRef,
}: FormInputsProps<initiatePaymentRequestWithoutQuantity>) => {
    const { handleSubmit, hasValidationErrors, invalid } = formRenderProps

    const { spacing, palette } = LuneTheme
    const { isMobile } = useIsMobile()
    const location = useLocation()

    const [stateData, setStateData] = useState<{
        isOnTestMode: boolean
        redirectData: {
            redirectUrl: string
            redirectLabel: string
        }
        bundle: Bundle
        quantity: string
    }>()

    useEffect(() => {
        if (location.state) {
            const { isOnTestMode, redirectData, bundle, quantity } = location.state
            const state = {
                isOnTestMode,
                redirectData,
                bundle,
                quantity,
            }
            setStateData(state)
        }
    }, [location])

    useEffect(() => {
        setLocalIdempotencyKey(idempotencyKey)
    }, [idempotencyKey])

    const stripeInputsStyle = useMemo(
        () => ({
            base: {
                padding: spacing(1),
                borderRadius: `8px`,
                color: `${palette.Grey900}`,
                fontWeight: '400',
                fontFamily: 'Helvetica Neue',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: `${palette.Grey900}`,
                },
                '::placeholder': {
                    color: `${palette.Grey700}`,
                },
                backgroundColor: cardErrorMessage ? `${palette.Red50}` : `${palette.Grey100}`,
            },
        }),
        [spacing, palette, cardErrorMessage],
    )

    return (
        <form
            onSubmit={(form) => {
                if (isMobile && hasValidationErrors && invalid && paymentFormRef.current) {
                    paymentFormRef.current.scrollIntoView({
                        behavior: 'smooth',
                    })
                }
                handleSubmit(form)
            }}
        >
            <Stack spacing={4}>
                <Stack spacing={1}>
                    <Field
                        name={FormKeys.EMAIL}
                        placeholder={'Email'}
                        component={FormInput}
                        validate={validateEmail}
                    />
                    <Field
                        name={FormKeys.NAME}
                        placeholder={'Name on card'}
                        component={FormInput}
                        validate={required}
                    />
                </Stack>
                <Stack spacing={1}>
                    {!stateData?.isOnTestMode ? (
                        <>
                            <StripeElementWrapper cardErrorMessage={cardErrorMessage}>
                                <CardNumberElement
                                    onChange={(e) =>
                                        setCardErrorMessage(e.error?.message || undefined)
                                    }
                                    options={{
                                        placeholder: 'Card number',
                                        showIcon: true,
                                        style: stripeInputsStyle,
                                    }}
                                />
                            </StripeElementWrapper>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: `50%`,
                                        marginRight: `8px`,
                                    }}
                                >
                                    <StripeElementWrapper cardErrorMessage={cardErrorMessage}>
                                        <CardExpiryElement
                                            onChange={(e) =>
                                                setCardErrorMessage(e.error?.message || undefined)
                                            }
                                            options={{
                                                style: stripeInputsStyle,
                                            }}
                                        />
                                    </StripeElementWrapper>
                                </Box>
                                <Box
                                    sx={{
                                        width: `50%`,
                                    }}
                                >
                                    <StripeElementWrapper cardErrorMessage={cardErrorMessage}>
                                        <CardCvcElement
                                            onChange={(e) =>
                                                setCardErrorMessage(e.error?.message || undefined)
                                            }
                                            options={{
                                                style: stripeInputsStyle,
                                            }}
                                        />
                                    </StripeElementWrapper>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <div>
                            <Field
                                name={FormKeys.TEST_OUTCOME}
                                validate={required}
                                component={({ input: { onChange, value }, meta }: any) => (
                                    <Select
                                        sx={{ width: '100%' }}
                                        label={'Test payment outcome'}
                                        items={[
                                            {
                                                label: 'Successful payment',
                                                value: 'success',
                                            },
                                            {
                                                label: 'Card declined',
                                                value: 'card_declined',
                                            },
                                        ]}
                                        value={value}
                                        onChange={(e) => onChange(e)}
                                        error={meta.touched && meta.error}
                                    />
                                )}
                            />
                        </div>
                    )}
                    {cardErrorMessage && (
                        <Box
                            sx={{
                                fontFamily: 'Helvetica Neue',
                                fontSize: `0.8rem`,
                                mt: 1,
                                color: `red`,
                            }}
                        >
                            {cardErrorMessage}
                        </Box>
                    )}
                </Stack>
                <Stack spacing={1}>
                    <Field name={FormKeys.COUNTRY} validate={required}>
                        {({ input: { onChange, value }, meta }: any) => (
                            <CountryPicker
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    setCountry(e)
                                }}
                                error={meta.touched && meta.error}
                            />
                        )}
                    </Field>
                    <Field
                        name={FormKeys.POST_CODE}
                        placeholder={'Postcode'}
                        component={FormInput}
                        validate={required}
                    />
                </Stack>

                <Stack spacing={1}>
                    <Field
                        name={FormKeys.VAT}
                        placeholder={'VAT number (optional)'}
                        component={FormInput}
                    />
                </Stack>

                {stateData?.isOnTestMode && (
                    <TestModeBanner>Test mode. You won’t be charged.</TestModeBanner>
                )}

                <Box
                    sx={{
                        ...(isMobile && {
                            background: 'white',
                            position: 'fixed',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            padding: ' 16px 24px',
                            boxShadow: 5,
                        }),
                    }}
                >
                    <Button
                        variant={'contained'}
                        loading={submittingPayment}
                        sx={{
                            width: '100%',
                        }}
                        type={'submit'}
                    >
                        Pay
                    </Button>
                </Box>
            </Stack>
        </form>
    )
}

export default PaymentFormInputs
