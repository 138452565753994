import React, { FC, RefObject, useEffect, useRef, useState } from 'react'

import useScrollRestoration from 'hooks/useScrollRestoration'

const ScrollableContainer: FC<{
    sessionStoragePositionName?: string
    children: React.ReactNode
}> = ({ children, sessionStoragePositionName }) => {
    const cardsContainer: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const scrollingContainer: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null)
    const { restoreScrollPosition, saveScrollPosition } = useScrollRestoration()

    const [windowWidth, setWindowSize] = useState(0)
    const [cardsContainerWidth, setCardsContainerWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWindowSize(document.body.clientWidth ? document.body.clientWidth : 0)
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        const containerWidth = cardsContainer.current?.clientWidth
        if (containerWidth) {
            setCardsContainerWidth(windowWidth - containerWidth)
        }
    }, [windowWidth])

    const onScroll = (e: any) => {
        const sl = e?.target?.scrollLeft
        if (sessionStoragePositionName) {
            saveScrollPosition(sl, sessionStoragePositionName)
        }
    }

    useEffect(() => {
        restoreScrollPosition(scrollingContainer, sessionStoragePositionName!)
    }, [])

    return (
        <div ref={cardsContainer}>
            <div
                onScroll={onScroll}
                ref={scrollingContainer}
                style={{
                    width: `calc(100% + ${cardsContainerWidth}px)`,
                    paddingLeft: cardsContainerWidth / 2,
                    marginLeft: cardsContainerWidth / -2,
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflow: 'auto',
                    alignItems: 'stretch',
                    justifyContent: 'flex-start',
                    flexDirection: 'row',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                    }}
                >
                    {children}
                </div>
            </div>
        </div>
    )
}
export default ScrollableContainer
