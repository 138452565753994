import { Box } from '@mui/material'
import { Button, LuneTheme, Modal, Text, useIsMobile } from 'lune-ui-lib'
import React, { FC } from 'react'

import { Bundle } from 'components/Bundles'
import { formatNumbers } from 'utils/Utils'

const InsufficientBundleModal: FC<{
    openModal: boolean
    setOpenModal: (open: boolean) => void
    bundle: Bundle
    onConfirm: () => void
}> = ({ openModal, setOpenModal, bundle, onConfirm }) => {
    const { palette } = LuneTheme
    const { isMobile } = useIsMobile()
    return (
        <Modal
            maxWidth={'sm'}
            fullWidth
            open={openModal}
            onClose={() => {
                setOpenModal(false)
            }}
            header={
                <Text
                    variant={'h5'}
                    sx={{
                        marginBottom: '-32px',
                        textAlign: 'center',
                    }}
                >
                    We only have {formatNumbers(bundle.availableQuantity)} tCO₂ of {bundle.name}
                </Text>
            }
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        gap: 1,
                        flexDirection: isMobile ? 'column' : 'row',
                    }}
                >
                    <Button variant={'outlined'} wide onClick={() => setOpenModal(false)}>
                        Cancel
                    </Button>
                    <Button variant={'contained'} wide onClick={onConfirm}>
                        Buy {formatNumbers(bundle.availableQuantity)} tCO₂
                    </Button>
                </Box>
            }
        >
            <Text
                variant={'caption'}
                sx={{
                    color: palette.Grey600,
                    textAlign: 'center',
                }}
            >
                We currently don’t have enough credits to fulfill your order. <br />
                You can buy {formatNumbers(bundle.availableQuantity)} tCO₂ or choose another bundle.
            </Text>
        </Modal>
    )
}

export default InsufficientBundleModal
