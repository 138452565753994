import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined'
import { Big } from 'big.js'
import { Button, Card, Tile } from 'lune-ui-lib'
import React, { useEffect, useState } from 'react'

import ScrollableContainer from 'components/ScrollableContainer'
import { Account } from 'pages/ChooseBundlePage/ChooseBundlePage'
import { formatToCurrency } from 'utils/Utils'

import LearnMoreBundleModal from './LearnMoreBundleModal'

enum OffsetType {
    EMISSIONS_REDUCTION = 'emissions_reduction',
    CARBON_REMOVAL = 'carbon_removal',
}

enum CarbonPermanence {
    LONG_TERM = 'long_term',
    SHORT_TERM = 'short_term',
}
export declare type ProjectSummary = {
    id: string
    name: string
    shortName: string
    description: string
    countryName: string
    countryCode: string
    logo?: string
    primaryImage?: string
    thumbnailImage?: string
}

export interface Bundle {
    id: string
    primaryImage: string
    backgroundColour: string
    description: string
    name: string
    offsetType?: OffsetType
    carbonPermanence?: CarbonPermanence
    unitPrice: string
    grossUnitPrice: string
    currency: string
    primaryImageHires: string
    smallThumbnail: string
    disabled: boolean
    availableQuantity: string
    projects: ProjectSummary[]
}

const BUNDLES_CONTAINER_SS = 'bundlesContainer'

const Bundles = ({
    bundles,
    totalAmount,
    account,
    onBundleSelect,
}: {
    bundles: Bundle[]
    totalAmount: string
    account: Account
    onBundleSelect: (bundle: Bundle) => void
}) => {
    const [amountAsBig, setAmountAsBig] = useState<Big>()
    const [openLearnMoreModal, setOpenLearnMoreModal] = useState<boolean>(false)
    const [learnMoreBundle, setLearnMoreBundle] = useState<Bundle>()

    useEffect(() => {
        let amountAsBig
        try {
            // Throws if amount is not a valid number
            amountAsBig = Big(totalAmount)
        } catch {
            amountAsBig = Big(0)
        }
        setAmountAsBig(amountAsBig)
    }, [totalAmount])

    return (
        <ScrollableContainer sessionStoragePositionName={BUNDLES_CONTAINER_SS}>
            {bundles.map((bundle, index) => {
                return (
                    <div
                        key={`bundle-${index}`}
                        style={{
                            minHeight: '530px',
                            width: '320px',
                            marginRight: '8px',
                            flex: '1 0 auto',
                        }}
                    >
                        <Card
                            backgroundImage={bundle.primaryImage}
                            gradientColor={bundle.backgroundColour}
                            title={<span>{bundle.name}</span>}
                            description={bundle.description}
                            buttons={[
                                <Button
                                    light
                                    key={`buy-button-${bundle.id}`}
                                    variant={'contained'}
                                    onClick={() => onBundleSelect(bundle)}
                                >
                                    Buy{' '}
                                    {!!amountAsBig &&
                                        `for ${formatToCurrency(
                                            Big(amountAsBig)
                                                .mul(Big(bundle.grossUnitPrice))
                                                .toNumber(),
                                            account.currency || bundle.currency,
                                        )}`}
                                </Button>,
                                <Button
                                    light
                                    key={`learn-more-button-${bundle.id}`}
                                    variant={'text'}
                                    onClick={() => {
                                        setLearnMoreBundle(bundle)
                                        setOpenLearnMoreModal(true)
                                    }}
                                >
                                    Learn More
                                </Button>,
                            ]}
                            tiles={[
                                <Tile
                                    key={`bundle-quantity-${index}`}
                                    icon={<PaymentsOutlinedIcon />}
                                    variant="light"
                                >
                                    {formatToCurrency(
                                        bundle.grossUnitPrice,
                                        account.currency || bundle.currency,
                                    )}{' '}
                                    / tCO2
                                </Tile>,
                                <Tile
                                    key={`bundle-offset-type-${index}`}
                                    icon={
                                        bundle.offsetType === OffsetType.EMISSIONS_REDUCTION ? (
                                            <CloudOffOutlinedIcon />
                                        ) : (
                                            <CloudDownloadOutlinedIcon />
                                        )
                                    }
                                    variant="light"
                                >
                                    {bundle.offsetType === OffsetType.EMISSIONS_REDUCTION
                                        ? 'Emission Reduction'
                                        : 'Carbon Removal'}
                                </Tile>,
                                <Tile
                                    key={`bundle-carbon-permanence-${index}`}
                                    icon={<LockOutlinedIcon />}
                                    variant="light"
                                >
                                    {!bundle.carbonPermanence
                                        ? 'No carbon storage'
                                        : bundle.carbonPermanence === CarbonPermanence.LONG_TERM
                                        ? 'Long-lived carbon storage'
                                        : 'Short-lived carbon storage'}
                                </Tile>,
                            ]}
                        />
                    </div>
                )
            })}
            {!!learnMoreBundle && (
                <LearnMoreBundleModal
                    bundle={learnMoreBundle}
                    openModal={openLearnMoreModal}
                    setOpenModal={setOpenLearnMoreModal}
                />
            )}
        </ScrollableContainer>
    )
}
export default React.memo(Bundles)
