import { Box } from '@mui/material'
import { Button, LoadingWrapper } from 'lune-ui-lib'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import FrameLayout from 'components/FrameLayout'
import PrivacyAndTerms from 'components/PrivacyAndTerms'
import TornPage from 'components/TornPage'
import { loadPaymentByTemporaryId } from 'endpoints/endpoints'
import SuccessPageDetails from 'pages/Success/SuccessPageDetails'

const background = require('images/gradientBg.png')

export interface OrderBundle {
    bundleId: string
    bundleName: string
    grossUnitPrice: string
    offsetCost: string
    quantity: string
    unitPrice: string
}

export interface Order {
    bundles: OrderBundle[]
    currency: string
    totalCost: string
    quantity: string
    commission: string
}

const SuccessPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [redirectData, setRedirectData] = useState<{
        redirectUrl: string
        redirectLabel: string
        externalId?: string
    }>()
    const [order, setOrder] = useState<Order>()

    useEffect(() => {
        if (location.state) {
            const { redirectData, temporaryId } = location.state
            setRedirectData(redirectData)
            loadPaymentByTemporaryId({ temporaryId }).then((res) => {
                setOrder(res.order)
            })
        } else {
            navigate('/')
        }
    }, [location])

    const redirectBack = () => {
        const redirectUrl = redirectData?.redirectUrl
        if (redirectUrl) {
            const formatUrl =
                redirectUrl.at(-1) === '/'
                    ? redirectUrl.slice(0, redirectUrl.length - 1)
                    : redirectUrl

            const queryParameters: string[] = [`temporary_id=${location.state?.temporaryId}`]
            if (location.state?.externalId) {
                queryParameters.push(`external_id=${location.state?.externalId}`)
            }

            const urlSuffix = `${formatUrl.includes('?') ? '&' : '?'}${queryParameters.join('&')}`
            const url = `${formatUrl}${urlSuffix}`
            window.location.replace(url)
        }
    }

    return (
        <FrameLayout>
            <Box
                sx={{
                    height: '100%',
                    pb: {
                        xs: 0,
                        sm: 2,
                    },
                    pl: {
                        xs: 0,
                        sm: 2,
                    },
                    pr: {
                        xs: 0,
                        sm: 2,
                    },
                }}
            >
                <Box
                    sx={{
                        borderRadius: {
                            xs: `0px`,
                            sm: `32px`,
                            md: `32px`,
                            lg: `32px`,
                            xl: `32px`,
                        },
                        backgroundImage: `url(${background})`,
                        backgroundSize: 'cover',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        padding: '40px 24px',
                    }}
                >
                    <TornPage>
                        <LoadingWrapper loading={!order} sx={{ minHeight: '400px' }}>
                            {order && <SuccessPageDetails order={order} />}
                        </LoadingWrapper>
                    </TornPage>
                    {redirectData?.redirectUrl && (
                        <Button
                            onClick={() => redirectBack()}
                            variant={'contained'}
                            sx={{
                                mt: 3,
                                width: '100%',
                                maxWidth: '440px',
                            }}
                        >
                            Back to {redirectData.redirectLabel || 'merchant'}
                        </Button>
                    )}
                    <Box
                        sx={{
                            mt: 3,
                        }}
                    >
                        <PrivacyAndTerms />
                    </Box>
                </Box>
            </Box>
        </FrameLayout>
    )
}

export default SuccessPage
