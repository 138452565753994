import { Box, Typography } from '@mui/material'
import { LuneTheme, useIsMobile } from 'lune-ui-lib'
import React, { FC } from 'react'

const TestModeBanner: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { palette } = LuneTheme
    const { isMobile } = useIsMobile()
    return (
        <Box
            sx={{
                background: palette.Yellow100,
                pl: isMobile ? 2 : 3,
                pr: isMobile ? 2 : 3,
                height: '56px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '8px',
                mb: 9,
            }}
        >
            <Typography variant={'body2'}>{children}</Typography>
        </Box>
    )
}

export default TestModeBanner
