import { Input, Text } from 'lune-ui-lib'

export const FormInput = ({
    disabled,
    input: { onChange, value, type, name },
    error,
    meta,
    placeholder,
    endAdornment,
    number,
    highlightOnFocus,
}: any) => (
    <Input
        data-testid={`input-${name}`}
        type={type}
        wide
        highlightOnFocus={highlightOnFocus}
        placeholder={placeholder}
        error={error || (meta.touched && meta.error)}
        value={value}
        onChange={(e) => {
            if (number) {
                const valueAsNumber = Number(e.target.value)
                return !Number.isNaN(valueAsNumber) ? onChange(valueAsNumber) : ''
            } else {
                return onChange(e)
            }
        }}
        disabled={disabled}
        endAdornment={<Text variant={'body2'}>{endAdornment}</Text>}
    />
)

export default FormInput
