import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import { Big } from 'big.js'
import { LuneTheme, Text } from 'lune-ui-lib'
import { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import TestModeBanner from 'components/TestModeBanner'
import { formatNumbers, formatToCurrency } from 'utils/Utils'

import '@fontsource/space-grotesk'

import { Order } from './SuccessPage'

import '@fontsource/space-grotesk/700.css'

const SuccessPageDetails: FC<{ order: Order }> = ({ order }) => {
    const { palette } = LuneTheme
    const location = useLocation()
    const [orderTotalCost, setOrderTotalCost] = useState<number>(0)
    const [vat, setVat] = useState<number>()
    const [detailsFromState, setDetailsFromState] = useState<{
        email: string
        temporaryId: string
        countryCode: string
        isOnTestMode: boolean
    }>()

    useEffect(() => {
        if (location.state) {
            const { email, temporaryId, countryCode, isOnTestMode } = location.state
            setDetailsFromState({ email, temporaryId, countryCode, isOnTestMode })
        }
    }, [location])

    useEffect(() => {
        if (detailsFromState?.countryCode === 'GBR' && order.commission) {
            const vat = (Number(order.commission) * 20) / 100
            setVat(vat)
            setOrderTotalCost(vat + Number(order.totalCost))
        } else {
            setOrderTotalCost(Number(order.totalCost))
        }
    }, [detailsFromState, order])

    const StyledHr = () => {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: '1px',
                    background: palette.Grey300,
                }}
            />
        )
    }
    return (
        <Box
            sx={{
                p: 3,
                pt: 5,
                pb: 5,
                width: '100%',
                height: '100%',
                '*': {
                    fontFamily: 'Space Grotesk !important',
                },
            }}
        >
            <Stack
                direction="column"
                spacing={{
                    xs: 4,
                }}
            >
                <div>
                    <Text variant={'h5'}>Thank you!</Text>
                    <Text variant={'body3'} sx={{ mt: 1 }}>
                        We’ll email a receipt to {detailsFromState?.email}
                    </Text>
                </div>
                <StyledHr />
                <div>
                    {order.bundles.map((bundle) => (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Text variant={'body3'}>{bundle.bundleName}</Text>
                            <Box
                                sx={{
                                    flex: '1 0 auto',
                                    textAlign: 'right',
                                }}
                            >
                                <Text variant={'body3'} sx={{ mr: 2 }}>
                                    {formatNumbers(bundle.quantity)} tCO2
                                </Text>
                                <Text variant={'body3'}>
                                    {formatToCurrency(
                                        Big(bundle.quantity).mul(Big(bundle.grossUnitPrice)),
                                        order.currency,
                                    )}
                                </Text>
                            </Box>
                        </Box>
                    ))}
                </div>
                <StyledHr />
                <div>
                    {vat && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                mb: 3,
                            }}
                        >
                            <Text variant={'body3'}>VAT</Text>
                            <Text variant={'body3'}>{formatToCurrency(vat, order.currency)}</Text>
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            '*': {
                                fontWeight: '700 !important',
                            },
                        }}
                    >
                        <Text variant={'body3'}>Total</Text>
                        <Box
                            sx={{
                                flex: '1 0 auto',
                                textAlign: 'right',
                            }}
                        >
                            <Text variant={'body3'} sx={{ mr: 2 }}>
                                {formatNumbers(order.quantity)} tCO2
                            </Text>
                            <Text variant={'body3'}>
                                {formatToCurrency(orderTotalCost, order.currency)}
                            </Text>
                        </Box>
                    </Box>
                </div>
                {detailsFromState?.isOnTestMode && (
                    <TestModeBanner>Test mode. You haven't been charged.</TestModeBanner>
                )}
            </Stack>
        </Box>
    )
}

export default SuccessPageDetails
