import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import ChooseBundlePage from 'pages/ChooseBundlePage/ChooseBundlePage'
import ConflictPage from 'pages/ConflictPage'
import PageNotFound from 'pages/PageNotFound'
import PaymentPage from 'pages/PaymentPage/PaymentPage'
import SuccessPage from 'pages/Success/SuccessPage'

function App() {
    return (
        <>
            <Helmet>
                {process.env.REACT_APP_ENABLE_PLAUSIBLE === 'true' && (
                    <script
                        defer
                        data-domain="pay.lune.co"
                        src="https://plausible.io/js/script.js"
                    ></script>
                )}
            </Helmet>
            <BrowserRouter>
                <Routes>
                    <Route path="/">
                        <Route index element={<PageNotFound />} />
                        <Route path={'*'} element={<PageNotFound />} />
                        <Route path={':accountId'} element={<ChooseBundlePage />} />
                        <Route path="pay" element={<PaymentPage />} />
                        <Route path="success" element={<SuccessPage />} />
                        <Route path="conflict" element={<ConflictPage />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    )
}

export default App
