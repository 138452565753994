import { Box } from '@mui/material'
import { Big } from 'big.js'
import { BackButton, Input, LoadingWrapper, LuneTheme, Text, useIsMobile } from 'lune-ui-lib'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Bundles, { Bundle } from 'components/Bundles'
import FrameLayout from 'components/FrameLayout'
import InsufficientBundleModal from 'components/InsufficientBundleModal'
import TestModeBanner from 'components/TestModeBanner'
import { loadBundles } from 'endpoints/endpoints'
import useQuery from 'hooks/useQuery'
import FAQs from 'pages/ChooseBundlePage/FAQs'

export interface Account {
    id: string
    type: 'live' | 'test'
    currency: string
}

function referrer(): string | undefined {
    const referrer = document.referrer

    if (!referrer.length) {
        return undefined
    }

    return referrer.startsWith('http') ? referrer : undefined
}

const ChooseBundlePage = () => {
    const navigate = useNavigate()

    const { palette } = LuneTheme
    const { isMobile } = useIsMobile()
    const { accountId } = useParams<{ accountId: string }>()
    const [quantity] = useQuery('quantity')
    const bundleIds = useQuery('bundle_id')
    const [externalId] = useQuery('external_id')
    const [redirectUrl] = useQuery('redirect_url')
    const [redirectLabel] = useQuery('redirect_label')

    const [bundles, setBundles] = useState<Bundle[]>()
    const [amount, setAmount] = useState<string>(quantity || '0')
    const [amountError, setAmountError] = useState<string | undefined>()
    const [isOnTestMode, setIsOnTestMode] = useState<boolean>(false)
    const [account, setAccount] = useState<Account>()

    const [insufficientBundleAmount, setInsufficientBundleAmount] = useState<boolean>(false)
    const [selectedBundle, setSelectedBundle] = useState<Bundle>()

    useEffect(() => {
        if (accountId && !bundles) {
            loadBundles({ accountId, bundleIds, externalId })
                .then((res) => {
                    setBundles(res.bundles)
                    setIsOnTestMode(res.accountType === 'test')
                    setAccount({
                        id: accountId,
                        type: res.accountType,
                        currency: res.accountCurrency,
                    })
                })
                .catch((e) => {
                    navigate(e.status === 409 ? '/conflict' : '/')
                })
        }
    }, [accountId, bundleIds])

    const onBackButtonClick = () => {
        navigate(-1)
    }

    const redirectToPayment = (bundle: Bundle, quantity: Big) => {
        window.scrollTo({ top: 0, behavior: 'auto' })

        const state = {
            account,
            bundle,
            bundleIds,
            quantity: quantity.toString(),
            requestedAmount: amount,
            isOnTestMode,
            redirectData: {
                redirectUrl,
                redirectLabel,
            },
            externalId,
        }

        navigate('/pay', {
            state,
        })
    }

    const onBundleSelect = (bundle: Bundle) => {
        setSelectedBundle(bundle)

        let amountAsBig
        try {
            // Throws if amount is not a valid number
            amountAsBig = Big(amount)
        } catch {
            window.scroll({ top: 0, behavior: 'smooth' })
            setAmountError('Amount should be a numeric value')
        }

        if (!amountAsBig) {
            return
        }

        if (amountAsBig.eq(Big(0))) {
            window.scroll({ top: 0, behavior: 'smooth' })
            setAmountError('Please enter an amount')
        } else if (amountAsBig.lt(Big(0))) {
            window.scroll({ top: 0, behavior: 'smooth' })
            setAmountError('Please enter a positive number')
        } else {
            if (bundle.availableQuantity && amountAsBig.gt(Big(bundle.availableQuantity))) {
                setSelectedBundle(bundle)
                setInsufficientBundleAmount(true)
            } else {
                redirectToPayment(bundle, amountAsBig)
            }
        }
    }

    return (
        <FrameLayout onBackButtonClick={referrer() ? () => onBackButtonClick() : undefined}>
            <LoadingWrapper loading={!account} sx={{ height: '100%' }}>
                {!!account && (
                    <>
                        <Box
                            sx={{
                                margin: 'auto',
                                mt: isMobile ? (isOnTestMode ? 0 : 5) : 0,
                                mb: isMobile ? 5 : 9,
                                padding: '0 24px',
                                maxWidth: '900px',
                            }}
                        >
                            {isOnTestMode && (
                                <TestModeBanner>Test mode. You won’t be charged.</TestModeBanner>
                            )}
                            <>
                                {!isMobile && referrer() && (
                                    <BackButton
                                        sx={{ mb: 3, ml: -2 }}
                                        onClick={onBackButtonClick}
                                    />
                                )}
                                <Text variant="h4" sx={{ mb: 4 }}>
                                    Choose how many tonnes to offset
                                </Text>
                            </>
                            <Box sx={{ mb: 9 }}>
                                <Input
                                    sx={{
                                        width: isMobile ? '100%' : '320px',
                                    }}
                                    error={amountError}
                                    value={amount}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        setAmount(val)
                                        if (val[0] === '-' || val === '0') {
                                            setAmountError('Please enter a positive number')
                                        } else if (val === '') {
                                            setAmountError('Please enter a value')
                                        } else if (isNaN(Number(val))) {
                                            setAmountError('Amount should be a numeric value')
                                        } else {
                                            setAmountError(undefined)
                                        }
                                    }}
                                    highlightOnFocus
                                    endAdornment={'tCO2'}
                                    hint={
                                        'Fractional tonnes will be retired with your next purchase.'
                                    }
                                />
                            </Box>
                            {!!bundles?.length && (
                                <>
                                    <Text variant={'h6'} sx={{ mb: 4 }}>
                                        Choose your favourite project bundle
                                    </Text>
                                    <Bundles
                                        account={account}
                                        bundles={bundles}
                                        totalAmount={amount}
                                        onBundleSelect={onBundleSelect}
                                    />
                                </>
                            )}
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '1px',
                                    background: palette.Grey300,
                                    mt: 9,
                                    mb: 9,
                                }}
                            />
                            <FAQs />
                        </Box>
                    </>
                )}
                {selectedBundle?.availableQuantity && (
                    <InsufficientBundleModal
                        openModal={insufficientBundleAmount}
                        setOpenModal={setInsufficientBundleAmount}
                        bundle={selectedBundle}
                        onConfirm={() =>
                            redirectToPayment(selectedBundle, Big(selectedBundle.availableQuantity))
                        }
                    />
                )}
            </LoadingWrapper>
        </FrameLayout>
    )
}

export default ChooseBundlePage
