import { Big } from 'big.js'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import BundlePaymentBreakdown from 'components/BundlePaymentBreakdown'
import FrameLayout from 'components/FrameLayout'
import PaymentFormWrapper from 'pages/PaymentPage/PaymentFormWrapper'
import PaymentLayout from 'pages/PaymentPage/PaymentLayout'

const FALLBACK_BACKGROUND_IMAGE_URL = 'https://assets.lune.co/bundles/default.png'

const PaymentPage = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [country, setCountry] = useState<string>()

    const bundle = location.state?.bundle
    const bundleIds = location.state?.bundleIds
    const account = location.state?.account
    const quantity = Big(location.state?.quantity || 0)
    const requestedAmount = Big(location.state?.requestedAmount || 0)
    const externalId = location.state?.externalId
    const redirectData: { redirectUrl?: string; redirectLabel?: string } =
        location.state?.redirectData

    const [idempotencyKey, setIdempotencyKey] = useState<string>()

    useEffect(() => {
        setIdempotencyKey(nanoid())
    }, [])

    useEffect(() => {
        if (!location.state) {
            navigate('/')
        }
    }, [location])

    const onBackButtonClick = () => {
        const bundlesQueryString = bundleIds.length
            ? `&bundle_id=${bundleIds.join('&bundle_id=')}`
            : ''
        const externalIdQueryString = externalId ? `&external_id=${externalId}` : ''

        const redirectUrlQueryString = redirectData.redirectUrl
            ? `&redirect_url=${redirectData.redirectUrl}`
            : ''
        const redirectLabelQueryString = redirectData.redirectLabel
            ? `&redirect_label=${redirectData.redirectLabel}`
            : ''

        navigate(
            {
                pathname: `/${account.id}`,
                search: `?quantity=${requestedAmount}${bundlesQueryString}${externalIdQueryString}${redirectUrlQueryString}${redirectLabelQueryString}`,
            },
            { state: location.state },
        )
    }

    if (account && !account.id) {
        navigate('/')
    }

    return (
        <FrameLayout onBackButtonClick={onBackButtonClick}>
            {bundle && account && (
                <PaymentLayout
                    backgroundImageUrl={bundle.largeImage || FALLBACK_BACKGROUND_IMAGE_URL}
                    columnOne={
                        <BundlePaymentBreakdown
                            bundle={bundle}
                            account={account}
                            quantity={quantity}
                            selectedCountry={country}
                            onBackButtonClick={onBackButtonClick}
                        />
                    }
                    columnTwo={
                        <PaymentFormWrapper
                            accountId={account.id!}
                            idempotencyKey={idempotencyKey!}
                            quantity={quantity}
                            setCountry={setCountry}
                        />
                    }
                    gradientColor={bundle.backgroundColour}
                />
            )}
        </FrameLayout>
    )
}

export default PaymentPage
